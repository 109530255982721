<template>
  <v-row>
    <v-col cols="12" class="d-flex align-center">
      <h2>{{ $t('bank.head') }}</h2>
      <v-divider class="mx-3" vertical />
      <v-breadcrumbs :items="breadcrumbsItems" divider=">>" />
    </v-col>
    <v-divider class="mx-3" vertical />
    <v-col cols="12">
      <v-card>
        <v-card-title>
          {{ $t('bank.head_list2') }}
          <v-spacer />
        </v-card-title>
        <v-form ref="form" v-model="formValid">
          <v-divider class="mx-3" />
          <v-card-actions v-if="datas.bank_type === 'UPLOAD_SLIP'">
            <v-alert
              border="left"
              dense
              outlined
              prominent
              text
              type="warning"
            >
              {{ $t('bank.warn1') }}<br/>
              {{ $t('bank.warn2') }}
            </v-alert>
          </v-card-actions>

          <!-- Bank Type -->
          <v-card-title class="pb-2">
            <v-card-text class="pa-0" no-gutters>{{ $t('bank.header16') }}</v-card-text>
          </v-card-title>
          <v-card-actions>
            <v-row>
              <v-col cols="12" xs="12" lg="4" md="6">
                <v-select v-model="datas.bank_type" :rules="[v => !!v || $t('alert_list.list9')]" :label="$t('bank.header16')"
                  :items="bankType" outlined dense />
              </v-col>
            </v-row>
          </v-card-actions>
          <v-divider class="mx-3" />

          <!-- Bank Information -->
          <v-card-title class="pb-2">
            <v-card-text class="pa-0" no-gutters>{{ $t('bank.head_list2') }}</v-card-text>
          </v-card-title>
          <v-card-actions>
            <v-row>
              <v-col cols="12" xs="12" lg="4" md="6">
                <v-select v-model="datas.bank" :rules="[v => !!v || $t('alert_list.list9')]" :label="$t('bank.header1')" :items="bankItems"
                  item-text="label" outlined dense @change="changeBanks" />
              </v-col>
              <v-col cols="12" xs="12" lg="4" md="6">
                <v-text-field v-model="datas.bank_name" :rules="[v => !!v || $t('alert_list.list9')]" :label="$t('bank.header2')" outlined
                  dense />
              </v-col>
              <v-col cols="12" xs="12" lg="4" md="6">
                <v-text-field v-model="datas.bank_account" :rules="[v => !!v || $t('alert_list.list9')]" :label="$t('account_number')" outlined
                  dense />
              </v-col>
            </v-row>
          </v-card-actions>

          <v-card-actions v-if="bankConfig === 'DEFAULT' && bankSystem === 'TH'">
            <v-row>
              <v-col cols="12" xs="12" lg="4" md="6">
                <v-select v-model="datas.kbank_device_type" :rules="[v => !!v || $t('alert_list.list9')]" :label="$t('type')"
                  :items="typeItems" outlined dense />
              </v-col>
              <v-col cols="12" xs="12" lg="4" md="6">
                <v-text-field v-model="datas.username" label="Username" outlined dense />
              </v-col>
              <v-col cols="12" xs="12" lg="4" md="6">
                <v-text-field v-model="datas.password" label="Password" outlined dense type="password" />
              </v-col>
              <v-col v-if="(datas.bank === 'KBANK') && (datas.kbank_device_type === 'app')" cols="12" xs="12" lg="4"
                md="6">
                <v-text-field v-model="datas.statefile" label="State File" outlined dense :rules="[v => !!v || $t('alert_list.list9')]" />
              </v-col>
              <v-col v-if="(datas.bank === 'KBANK') && (datas.kbank_device_type === 'app')" cols="12" xs="12" lg="4"
                md="6">
                <v-text-field v-model="datas.pin" label="PIN" outlined dense :rules="[v => !!v || $t('alert_list.list9')]" />
              </v-col>
              <v-col v-if="datas.bank !== 'KBANK'" cols="12" xs="12" lg="4" md="6" class="d-flex">
                <v-text-field v-model="datas.promptpay" :label="$t('bank.header10')" outlined dense class="pr-1" />
              </v-col>
            </v-row>
          </v-card-actions>

          <v-card-actions v-if="datas.bank_type === 'default' && bankConfig === 'GSB'">
            <v-row>
              <v-col cols="12" xs="12" lg="4" md="6">
                <v-text-field
                v-model="datas.pin"
                label="PIN"
                outlined dense
                :rules="[v => !!v || $t('alert_list.list9')]"
              />
              </v-col>
              <v-col cols="12" xs="12" lg="4" md="6">
                <v-text-field
                  v-model="datas.device_id_scb"
                  label="Device ID"
                  outlined dense
                  :rules="[v => !!v || $t('alert_list.list9')]"
                />
              </v-col>
              <v-col cols="12" xs="12" lg="4" md="6">
                <v-text-field
                  v-model="datas.user_identity"
                  label="Citizen ID"
                  outlined dense
                  :rules="[v => !!v || $t('alert_list.list9')]"
                />
              </v-col>
            </v-row>
          </v-card-actions>
          <v-card-actions v-if="bankConfig === 'SCB'">
            <v-row v-if="datas.bank_type === 'default'">
              <v-col cols="12" xs="12" lg="4" md="6">
                <v-text-field v-model="datas.pin" label="PIN" outlined dense :rules="[v => !!v || $t('alert_list.list9')]" />
              </v-col>
              <!-- <v-col cols="12" xs="12" lg="4" md="6">
                <v-text-field v-model="datas.otp" label="OTP" outlined dense />
              </v-col> -->
              <!-- <v-col cols="12" xs="12" lg="4" md="6">
                <v-text-field v-model="datas.msisdn" label="msisdn" outlined dense />
              </v-col> -->
              <v-col cols="12" xs="12" lg="4" md="6">
                <v-text-field v-model="datas.device_id_scb" label="Device ID" outlined dense :rules="[v => !!v || $t('alert_list.list9')]" />
              </v-col>
              <!-- <v-col cols="12" xs="12" lg="4" md="6">
                <v-btn color="primary" :loading="loading" class="px-3" @click="requestOTP">
                  <span class="px-3">Request OTP</span>
                </v-btn>
                <v-btn color="primary" style="margin-left: 15px;" @click="recieptTag()">
                  <span class="px-3">รับ TAG</span>
                </v-btn>
              </v-col> -->
            </v-row>

            <v-row v-if="datas.bank_type === 'SMS' || datas.bank_type === 'SMSQR'">
              <v-col cols="12" xs="12" lg="4" md="6" class="d-flex">
                <v-text-field v-model="datas.promptpay" :label="$t('bank.header10')" outlined dense :rules="[v => !!v || $t('alert_list.list9')]" class="pr-1" />
              </v-col>
            </v-row>
          </v-card-actions>
          <v-card-actions v-if="datas.bank_type === 'default' && bankConfig === 'KTB'">
            <v-row>
              <v-col cols="12" xs="12" lg="4" md="6">
                <v-text-field v-model="datas.account_token_number" label="Account token number" outlined dense />
              </v-col>
              <v-col cols="12" xs="12" lg="4" md="6">
                <v-text-field v-model="datas.user_identity" label="User identity" outlined dense />
              </v-col>
              <v-col cols="12" xs="12" lg="4" md="6" class="d-flex">
                <v-text-field v-model="datas.user_token_identity" label="User token identity" outlined dense
                  style="width: 400px" class="pr-1" />
                <!-- <v-select label="สถานะ" :items="statusItems" outlined dense class="pl-5" /> -->
              </v-col>
            </v-row>
          </v-card-actions>
          <v-card-actions v-if="datas.bank_type === 'default' && bankConfig === 'TRUEWALLET'">
            <v-row>
              <v-col cols="12" xs="12" lg="2" md="6">
                <v-text-field v-model="datas.tmn_key_id" label="TMN Key ID" placeholder="เช่น 1234" outlined dense />
              </v-col>
              <v-col cols="12" xs="12" lg="2" md="6">
                <v-text-field v-model="datas.tmn_id" label="TMN ID" placeholder="เช่น tmn.123456789" outlined dense />
              </v-col>
              <v-col cols="12" xs="12" lg="3" md="6">
                <v-text-field v-model="datas.pin" label="PIN" placeholder="เช่น 123456" outlined dense />
              </v-col>
              <v-col cols="12" xs="12" lg="3" md="6">
                <v-text-field v-model="datas.login_token" placeholder="เช่น L-3b3ab133-xxxx-xxxx-xxxx-xxxxxxxxxx"
                  label="Login Token" outlined dense />
              </v-col>
            </v-row>
          </v-card-actions>
          <v-divider class="mx-3" />

          <v-card-title class="pb-2">
            <v-card-text class="pa-0" no-gutters>{{ $t('bank.head_list4') }}</v-card-text>
          </v-card-title>
          <v-card-actions>
            <v-row>
              <v-col cols="12" xs="12" lg="4" md="6">
                <v-text-field v-model="datas.time_from" :label="$t('bank.header11')" outlined dense
                  :hint="$t('bank.header11_hint')" :rules="bankConfig !== 'SCB' ? [v => !!v || $t('alert_list.list9')] : []" />
              </v-col>
              <v-col cols="12" xs="12" lg="4" md="6">
                <v-text-field v-model="datas.time_to" :label="$t('bank.header12')" outlined dense
                  :rules="bankConfig !== 'SCB' ? [v => !!v || $t('alert_list.list9')] : []" :hint="$t('bank.header12_hint')" />
              </v-col>
            </v-row>
          </v-card-actions>
          <v-card-actions>
            <v-row>
              <v-col cols="12" xs="12" lg="4" md="6">
                <v-select v-model="datas.use" :label="$t('bank.header6')" :items="statusItems" outlined dense />
              </v-col>
              <v-col cols="12" xs="12" lg="4" md="6">
                <v-select v-model="datas.use_withdraw" :label="$t('bank.header7')" :items="statusItems" outlined dense />
              </v-col>
              <v-col cols="12" xs="12" lg="4" md="6">
                <v-text-field v-if="datas.use_withdraw" v-model="datas.limit_withdraw" :label="$t('bank.header5')"
                  outlined type="number" :rules="bankConfig !== 'SCB' ? [v => !!v || $t('alert_list.list9')] : []" dense />
              </v-col>
              <v-col cols="12" xs="12" lg="8" md="10">
                <v-textarea v-model="datas.detail" :label="$t('bank.header13')" outlined dense />
              </v-col>
            </v-row>
          </v-card-actions>

          <v-divider class="mx-3" />

          <v-card-actions>
            <v-spacer />
            <v-btn color="warning" @click="$router.push({ name: 'bank' })">
              <v-icon>mdi-arrow-left</v-icon>
              <span class="px-3">{{ $t('back') }}</span>
            </v-btn>
            <v-btn color="success" :loading="loading" class="px-3" @click="save">
              <v-icon>mdi-content-save</v-icon>
              <span class="px-3">{{ $t('save') }}</span>
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import axios from 'axios'
import moment from 'moment'
import DatePickerInput from '@/components/input/DatePickerInput.vue'
import store from '@/store'
import { KRBanks } from '@/utils/KRBanks.ts'
import { LABanks } from '@/utils/LABanks.ts'
import { KHBanks } from '@/utils/KHBanks.ts'

export default {
  components: {
    DatePickerInput,
  },
  data() {
    return {
      formValid: true,
      loading: false,
      connectBankDialog: false,
      formRule: {
        username: [
          value => !!value || 'username is required.',
          value => (!!value && value.length > 5) || 'need more than 5 characters',
        ],
        password: [
          value => !!value || 'password is required.',
          value => (!!value && value.length > 5) || 'need more than 5 characters',
        ],
        number: [value => !!value || 'number is required.', value => (!!value && value > 0) || 'need more than 0'],
      },
      datas: {
        bank: null,
        bank_name: '',
        bank_account: null,
        username: null,
        password: null,
        use: true,
        use_withdraw: true,
        time_from: null,
        time_to: null,
        limit_withdraw: 0,
        promptpay: null,
        account_token_number: null,
        user_identity: null,
        user_token_identity: null,
        identity_id: null,
        birth_date: null,
        mobile_phone: null,
        otp: null,
        pin: null,
        tmn_key_id: null,
        tmn_id: null,
        login_token: null,
        token_uuid: null,
        auth: null,
        device_id_scb: null,
        msisdn: null,
        statefile: null,
        kbank_device_type: 'web'
      },
      breadcrumbsItems: [
        {
          text: this.$t('bank.head'),
          disabled: false,
          href: '/settingBank',
        },
        {
          text: this.$t('bank.create'),
          disabled: true,
          href: '',
        },
      ],
      withdrawAccountStatus: false,
      bankItems: [],
      bankType:[
        {
          text: 'DEFAULT',
          value: 'default',
        },
        {
          text: 'SMS',
          value: 'SMS',
        },
        {
          text: 'SMS_QRCODE',
          value: 'SMSQR',
        },
        {
          text: 'TRUEMONEY',
          value: 'TRUEMONEY',
        },
        {
          text: 'UPLOAD_SLIP',
          value: 'UPLOAD_SLIP',
        },
      ],
      typeItems: [
        {
          text: 'Web',
          value: 'web',
        },
        {
          text: 'App',
          value: 'app',
        },
      ],
      data_scbotp: {
        card_id: null,
        date_of_birth: null,
        phone: null,
      },
      statusItems: [
        {
          text: this.$t('open'),
          value: true,
        },
        {
          text: this.$t('close'),
          value: false,
        },
      ],
      banksTypeConfig: [
      {
          bank: 'GSB',
        },
        {
          bank: 'SCB',
        },
        {
          bank: 'KTB',
        },
        {
          bank: 'TRUEWALLET',
        },
        {
          bank: 'DEFAULT',
        },
      ],
      bankConfig: null,
      maxDate: moment().format('YY-MM-DD'),
      bankSystem: '',
    }
  },
  async created() {
    try {
      this.bankItems = await this.$store.dispatch('getTypeBankData')

      let settingWebInfo = await this.$store.dispatch('getSettingWebInfo')
      this.bankSystem = settingWebInfo?.bank_system[0] || 'TH';
      this.bankConfig = this.banksTypeConfig[this.banksTypeConfig.length - 1].bank
    } catch (e) { }

    await this.addLogPage()
    await this.getBankList()
  },
  methods: {
    async addLogPage() {
      try {
        let data
        const userSystem = store.getters.getuserInfo
        await axios.get('https://api.ipify.org?format=json').then(res =>
          data = {
            ip: res.data.ip,
            name: 'ตั้งค่าธนาคาร',
            url: window.location.href,
            detail: 'เพิ่มธนาคาร',
            admin: userSystem.name ? userSystem.name : '' ? userSystem.name : '',
          }
        )
        await this.$store.dispatch('addLogPage', data)
      } catch (e) {
        // if (!e.message.includes('Unexpected token') || !e?.includes('Unexpected token') || !e.message.includes('Unexpected identifier')) {
        //   this.$swal.fire({
        //     icon: 'error',
        //     title: e.message,
        //   })
        // }
      }
    },
    async getBankList(){
      let bankList;
      let bankCountry = this.bankSystem;
      this.bankCountry = bankCountry;
      switch(bankCountry) {
        case 'LA':
          bankList = LABanks
          break;
        case 'KR':
          bankList = KRBanks
          break;
        case 'KH':
          bankList = KHBanks
          break;
        default:
          bankList = this.bankItems
        break;
      }
      const formattedBanks = bankList.map(bank => ({
        label: bankCountry === 'TH' ? bank.label :`${bank.bank_name} (${bank.bank_name_en})`,
        value: bankCountry === 'TH' ? bank.value : bank.bank_code,
      }));
      this.bankItems = formattedBanks
      this.bankType = bankCountry === 'TH'
        ? this.bankType
        : this.bankType.filter((item)=> (item.value !== 'SMS' || item.value !== 'SMSQR') && item.value !== 'TRUEMONEY' )
    },
    async save() {
      this.loading = true
      if (this.$refs.form.validate()) {
        const params = { ...this.datas }

        try {
          params.bank = !params.bank.startsWith(this.bankSystem) ? `${this.bankSystem}${params.bank}` : params.bank
          params.limit_withdraw = parseFloat(params.limit_withdraw)
          const res = await this.$store.dispatch('addAccountBank', params)
          this.$swal.fire('สร้างบัญชีธนาคารสำเร็จ', '', 'success').then(action => {
            this.$router.push({ name: 'bank' })
          })
        } catch (e) {
          if (!e.message.includes('Unexpected token') || !e?.includes('Unexpected token') || !e.message.includes('Unexpected identifier')) {
            this.$swal.fire({
              icon: 'error',
              title: e.message,
            })
          }
        }
      }
      this.loading = false
    },
    changeBanks() {
      if (this.bankSystem === 'TH') {
        let configBank = this.banksTypeConfig.find(item => item.bank === this.datas.bank)
        if (configBank) {
          this.bankConfig = configBank.bank
        } else {
          configBank = this.banksTypeConfig.find(item => item.bank === 'DEFAULT')
          this.bankConfig = configBank.bank
        }
      } else {
        this.bankConfig = 'DEFAULT'
      }
    },
    async requestOTP() {
      this.loading = true
      this.data_scbotp.card_id = this.datas.identity_id
      this.data_scbotp.date_of_birth = this.datas.birth_date
      this.data_scbotp.phone = this.datas.mobile_phone
      const resRegister = await this.$store.dispatch('scbRequestOTP', this.data_scbotp)
      this.datas.token_uuid = resRegister.data.msg
      this.datas.auth = resRegister.data.Auth
      if (resRegister.data.status !== 200) {
        this.$swal.fire({
          icon: 'error',
          title: resRegister.data.err,
        })
      }
      this.loading = false
    },

    recieptTag() {
      const time = new Date().getTime()
      const url = `http://info-msisdn.scb.co.th:8080/msisdn?date=${time}`
      window.open(url, '_blank').focus();
    }
  },
}
</script>
